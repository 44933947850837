import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Col, Row } from 'react-bootstrap'
import Layout from '../../components/layout'

/**
 * Single blog post page
 *
 * @param {object} data
 * @returns {JSX} blog post page
 */
const BlogPost = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.hero_image)
  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <p>Posted: {data.mdx.frontmatter.date}</p>
      <Row>
        <Col xs={12} md={4}>
          <GatsbyImage
            image={image}
            alt={data.mdx.frontmatter.hero_image_alt}
          />
        </Col>
        <Col xs={12} md={8}>
          <MDXRenderer>
            {data.mdx.body}
          </MDXRenderer>
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY")
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
  `

export default BlogPost